import { useState, useEffect } from 'react';

export interface UseFormProps<T> {
  validator?: (formData: T) => boolean;
  initialData?: Partial<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useForm<T = any>(params?: UseFormProps<T>) {
  const [form, setForm] = useState<T>((params?.initialData ?? {}) as T);
  const isFormInvalid = params?.validator ? params?.validator(form) : false;

  function handleChange(value: string | number | boolean, name: keyof typeof form) {
    setForm({ ...form, [name]: value });
  }

  return {
    form,
    setForm,
    handleChange,
    isFormInvalid
  };
}
