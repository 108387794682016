import Swal from 'sweetalert2';

interface BaseAlertParams {
  title?: string;
  message?: string;
  icon?: string;
  allowEnterKey?: boolean,
  allowEscapeKey?: boolean,
  allowOutsideClick?: boolean,
  showConfirmButton?: boolean,
  denyButtonText?: string;
  confirmButtonText?: string,
}

export interface AlertParams {
  buttonText?: string;
  denyButtonText?: string;
}

const Alert = {
  close: () => {
    Swal.close();
  },

  fire: (params: BaseAlertParams) => {
    return Swal.fire({
      showCloseButton: false,
      focusConfirm: false,
      focusCancel: false,
      focusDeny: false,
      returnFocus: false,
      buttonsStyling: false,
      confirmButtonText: params.confirmButtonText || 'Ok, entendi!',
      showConfirmButton: params.showConfirmButton !== false,
      denyButtonText: params.denyButtonText,
      showDenyButton: !!params.denyButtonText?.length,
      allowEnterKey: params.allowEnterKey !== false,
      allowEscapeKey: params.allowEscapeKey !== false,
      allowOutsideClick: params.allowOutsideClick !== false,
    });
  },

  await: (message?: string, title = 'Aguarde...') => {
    Swal.fire({
      title,
      text: message,
      showCloseButton: false,
      focusConfirm: false,
      focusCancel: false,
      focusDeny: false,
      returnFocus: false,
      buttonsStyling: false,
      showConfirmButton: false,
      showDenyButton: false
    });
  },

  success: (message?: string, title = 'Sucesso!') => {
    Swal.fire({
      title,
      text: message,
    });
  },

  error: (message?: string, title = 'Oops..', options?: AlertParams) => {
    Swal.fire({
      title,
      text: message,
    });
  },

  confirm: async (message?: string, title = 'Atenção!', options?: AlertParams) => {
    const response = await Swal.fire({
      title,
      text: message,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sim, tenho certeza!',
      denyButtonText: 'Não, quero cancelar!',
    });

    return response.isConfirmed;
  },};

export default Alert;
