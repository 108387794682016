import React from 'react';
import Str from '../../../helpers/Str';

export interface FormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  children?: React.ReactNode;
}

export default function Form({
  onSubmit,
  children,
  className,
  ...rest
}: FormProps) {
  function handleSubmit(e: any) {
    e.preventDefault();
    if (onSubmit) onSubmit(e);
  }

  return (
    <form {...rest} className={Str.tw(className)} onSubmit={handleSubmit}>
      {children}
    </form>
  );
}
